import React from 'react';
import Clear from 'public/img/Clear.svg';
import { StyledAsyncSelect } from './AsyncSelect.styled';

// eslint-disable-next-line @next/next/no-img-element
const ClearIcon = () => <img src={Clear} alt="clear" />;
const ClearIndicator = props => {
  const {
    children = <ClearIcon />,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
    >
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

export const AsyncSelect = props => (
  <StyledAsyncSelect
    classNamePrefix="react-async-select"
    {...props}
    components={{
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
      LoadingIndicator: () => null,
      ClearIndicator,
    }}
  />
);
