import React from 'react';

import { StyledFullScreenRow, StyledFullScreenContent, StyledLink } from './FullScreenRow.styled';

export const FullScreenRow = ({
  active,
  onBack,
  children,
  ...restProps
}) => (
  <StyledFullScreenRow active={active} data-testid="fullscreen-row">
    <StyledFullScreenContent active={active}>
      <StyledLink
        href="#"
        onClick={(evt) => {
          evt.preventDefault();
          onBack();
        }}
      >
        {'< Back'}
      </StyledLink>
    </StyledFullScreenContent>

    {React.cloneElement(children, { ...restProps })}
  </StyledFullScreenRow>
);
