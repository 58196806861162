import { CheckboxStyled } from 'src/js/nextgen/forms/Inputs/Checkbox/Checkbox.styled';

interface props {
    id: string,
    label: string,
    onChange: any,
    checked: boolean,
    name: string,
    value: any,
    title?: string,
    disabled?: boolean,
}
const CheckboxV2 = (props: props) => {
  const {
    id, name, label, onChange, checked, value, title, disabled = false,
  } = props;

  // eslint-disable-next-line react/destructuring-assignment
  return (
    <CheckboxStyled>
      <input name={name || id} type="checkbox" id={id} onChange={onChange} checked={checked} value={value} aria-labelledby={`${id}-label`} title={title || `${name} checkbox`} disabled={disabled} />
      <label htmlFor={id} id={`${id}-label`}>
        {label}
      </label>
    </CheckboxStyled>
  );
};

export default CheckboxV2;
