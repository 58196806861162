import React from 'react';
import FormContext from 'src/js/nextgen/forms/FormContext';
import Input from 'src/js/nextgen/plushcare-components/Input';

const TextInput = ({
  name,
  formik,
  disabled,
  readOnly,
  placeholder,
  className = '',
  type = 'text',
  maxLength,
  css,
  ariaLabel,
  mask,
  onClick,
  testId,
}) => {
  const { formik: contextFormik } = React.useContext(FormContext);
  const frmk = formik || contextFormik;
  const hasError = frmk.touched && frmk.touched[name] && frmk.errors[name];

  return (
    <Input
      type={type || 'text'}
      className={`input ${className} ${hasError ? 'input-error' : ''}`.trim()}
      name={name}
      onChange={frmk.handleChange}
      onBlur={frmk.handleBlur}
      disabled={disabled || frmk.isSubmitting}
      placeholder={placeholder}
      readOnly={readOnly}
      value={frmk.values[name] || ''}
      maxLength={maxLength}
      data-private="lipsum"
      css={css}
      ariaLabel={ariaLabel}
      mask={mask}
      onClick={onClick}
      testId={testId}
    />
  );
};

export default TextInput;
