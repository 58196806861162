import React from 'react';
import { useFormik } from 'formik';

import FormContext from 'src/js/nextgen/forms/FormContext';
import { isFunction } from 'src/js/utils/functools';

export const Form = (props) => {
  const {
    initialValues = {},
    validationSchema,
    onSubmit,
    children: childrenOrFunction,
    className,
    preventResetOnSubmit = false,
  } = props;
  const [isMounted, setIsMounted] = React.useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      if (onSubmit) {
        // TODO: this outer Promise.resolve() might not be necessary?
        await Promise.resolve(onSubmit(values, actions));
        // TODO:: discuss this behaviour
        if (isMounted && !preventResetOnSubmit) {
          actions.setSubmitting(false);
          actions.resetForm();
        }
      }
    },
  });

  React.useEffect(
    () => {
      setIsMounted(true);
      return () => setIsMounted(false);
    },
    [formik],
  );

  const onSubmitFormHandler = React.useCallback(
    (e) => {
      e.preventDefault();
      formik.handleSubmit();
      return false;
    },
    [formik],
  );

  const context = React.useMemo(
    () => ({
      formik,
    }),
    [formik],
  );

  const children = React.useMemo(
    () => (isFunction(childrenOrFunction) ? childrenOrFunction(formik) : childrenOrFunction),
    [childrenOrFunction, formik],
  );

  return (
    <FormContext.Provider value={context}>
      <form method="post" onSubmit={onSubmitFormHandler} className={className}>
        {children}
      </form>
    </FormContext.Provider>
  );
};
