import { css } from '@emotion/react';
import { colors } from '../../plushcare-components/helpers/plushcare-style-defaults';

export const baseInputStyles = props => css`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 40px;
  padding: 0px 8px;
  font-size: 16px;
  line-height: 20px;
  color: ${props.theme.colors.foreground};

  border: 1px solid;
  border-color: ${props.error ? colors.red : props.theme.colors.primaryBackground};
  border-radius: 8px;

  &:focus, &:active {
    border-color: ${props.theme.colors.foreground};
    outline: none;
  }

  &:disabled {
    background: ${colors.white};
    color: ${colors.adaGray};
    border-color: ${colors.rain};
  }

  &::placeholder {
    color: ${colors.placeholderGray};
  }
`;

export const baseLabelStyles = (props) => css`
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  color: ${props.theme.colors.foreground};
  text-align: left;
`;
