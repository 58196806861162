import styled from '@emotion/styled';
import { colors } from 'src/js/nextgen/plushcare-components';

export const LabelStyled = styled.label`
  margin-bottom: 6px;
  font-size: 15px;
  line-height: 17px;
  color: ${props => props.theme.colors?.foreground};
  font-weight: 600;
`;

export const HelpText = styled.div`
  font-size: 12px;
  color: ${colors.plushcareGrey};
  margin-top: 8px;

  &.has-error {
    color: ${colors.red};
  }
`;

export const FieldContainer = styled.div`
  margin-bottom: 20px;

  label.input {
    margin: 0;
  }

  .control {
    input {
      margin: 0;
    }
  }

  &.input-error input {
    margin-bottom: 0 !important;
  }
`;
