import React from 'react';
import { SelectInput } from '..';
import Field from './Field';
import FormContext from '../FormContext';

const SelectInputField = ({
  label, name, formik, disabled, children,
}) => {
  const context = React.useContext(FormContext);
  // eslint-disable-next-line react/destructuring-assignment
  const frmk = formik || context.formik;

  return (
    <Field label={label} formik={frmk} name={name}>
      <SelectInput
        formik={frmk}
        name={name}
        disabled={disabled}
        ariaLabel={label}
      >
        {children}
      </SelectInput>
    </Field>
  );
};

export default SelectInputField;
