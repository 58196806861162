import styled from '@emotion/styled';
import { colors } from 'src/js/nextgen/plushcare-components/helpers/plushcare-style-defaults';

export const FieldContainer = styled.label`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  padding: 10px 0px;
`;

export const Label = styled.label`
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  color: ${props => props.theme.colors.foreground};
  text-align: left;
`;

export const Error = styled.div`
  min-height: 15px;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.adaRed};
  text-align: left;
  margin-top: 3px;
`;

export const Hint = styled.div`
  min-height: 15px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.adaGray};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 10px;
`;
