/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from '@emotion/styled';
import iconValid from 'public/img/icon-valid.svg';
import iconInvalid from 'public/img/icon-invalid.svg';
import { TextInput } from '..';
import Field from './Field';
import FormContext from '../FormContext';
import { DEFAULT_PASSWORD_CHECKS } from '../YupHelpers/password';
import { MediaContext } from '../../context';
import { colors } from '../../plushcare-components/helpers/plushcare-style-defaults';

const PasswordHintsList = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  color: ${colors.gray};

  &.label {
    color: ${props => props.theme.colors.foreground};
  }

  & > li {
    color: ${colors.adaRed};
    font-size: 14px;
    line-height: 16px;
    background: left 2px url(${iconInvalid}) no-repeat;
    background-size: 10px;
    padding-left: 18px;

    &.is-completed {
      background: left 0 url(${iconValid}) no-repeat;
      color: ${props => props.theme.colors.foreground};
    }
  }
`;

const PasswordHint = ({
  errorKey, errors, label, value,
}) => {
  const isCompleted = value && errors && errors.indexOf(errorKey) === -1;
  return (
    <li className={`${isCompleted ? 'is-completed' : ''}`}>
      {label}
    </li>
  );
};

const PasswordHints = ({ errors, value, passwordChecks }) => {
  const errorsArray = (errors || '').split('|');
  return (
    <PasswordHintsList className="password-hints">
      {passwordChecks.map((check) => {
        const [errorKey,, message] = check;
        return (
          <PasswordHint
            key={errorKey}
            errorKey={errorKey}
            label={message}
            errors={errorsArray}
            value={value}
          />
        );
      })}
    </PasswordHintsList>
  );
};

const TogglePasswordLink = styled.a`
  padding: 10px 0;
  display: inline-block;
  color: ${colors.adaRoyalBlue};
`;

const PasswordInputField = ({
  label, name, formik, disabled, readOnly, placeholder, help, displayHints, passwordChecks,
  passwordValue,
}) => {
  const { formik: contextFormik } = React.useContext(FormContext);
  const frmk = formik ?? contextFormik;

  const [showPasswordManuallySwitched, setShowPasswordManuallySwitched] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    setShowPasswordManuallySwitched(true);
    return false;
  };

  const mediaContext = React.useContext(MediaContext);

  const isPasswordShown = (showPasswordManuallySwitched && showPassword)
    || (!showPasswordManuallySwitched && mediaContext.isMobile);

  const renderPasswordHints = () => (
    <PasswordHints
      errors={frmk.errors[name]}
      touched={frmk.touched[name]}
      value={frmk.values[name]}
      passwordChecks={passwordChecks || DEFAULT_PASSWORD_CHECKS}
    />
  );

  return (
    <Field label={label} formik={frmk} name={name} help={help} disableErrors={displayHints}>
      <TextInput
        formik={frmk}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        value={passwordValue}
        type={isPasswordShown ? 'text' : 'password'}
      />
      <TogglePasswordLink
        href="#"
        onClick={toggleShowPassword}
      >
        {isPasswordShown ? 'Hide password' : 'Show password'}
      </TogglePasswordLink>
      {displayHints && renderPasswordHints()}
    </Field>
  );
};

export default PasswordInputField;
