import React from 'react';

const MediaContext = React.createContext({
  isMobile: false, // Up to 768px
  isTablet: false, // Between 769px and 1023px
  isDesktop: false, // 1024px and above
  isWidescreen: false, // 1216px and above
  isFullHD: false, // 1408px and above
});

export default MediaContext;
