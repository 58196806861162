import React from 'react';
import { CheckboxStyled } from './Checkbox.styled';
import FormContext from '../../FormContext';

const Checkbox = (props) => {
  const {
    id, label, formik, selected,
  } = props;
  const context = React.useContext(FormContext);
  // eslint-disable-next-line react/destructuring-assignment
  const frmk = formik || context.formik;
  return (
    <CheckboxStyled>
      <input type="checkbox" {...props} onChange={frmk.handleChange} defaultChecked={selected} />
      <label htmlFor={id}>{ label }</label>
    </CheckboxStyled>
  );
};

export default Checkbox;
