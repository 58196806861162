import React from 'react';
import styled from '@emotion/styled';
import InputMask from 'react-input-mask';

import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';
import ErrorMessage from './ErrorMessage';
import { colors } from './helpers/plushcare-style-defaults';
import { themes } from '../themes';

const InputTextStyled = styled.label`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 40px;
    padding: 12px 12px 9px;
    border: 1px solid ${colors.cloud};
    border-radius: 8px;
    margin: 24px 0 20px;
    font-size: 15px;
    line-height: 20px;
    color: ${props => props.theme.colors.foreground};

    &:focus {
      border-color: ${props => props.theme.colors.foreground};
      outline: none;
    }

    &::placeholder {
      color: ${colors.placeholderGray};
    }
  }

  &.acc-input {
    input {
      border-color: ${props => props.theme.colors.gray4};
      &:hover, &:focus {
        border-color: ${props => props.theme.colors.primaryHover};
      }
    }
  }

  &.helper-message input {
    margin-bottom: 0;
  }

  &.input-error input,
  &.acc-input.input-error input {
    border-color: ${colors.red};
    margin-bottom: 3px;
  }

  .label {
    display: block;
    width: 100%;
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    color: ${props => props.theme.colors.foreground};
    text-align: left;

    & + .input-container > input {
      margin-top: 0;
    }
  }

  .input-container {
    width: 100%;
  }

  .helper-message {
    min-height: 15px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 10px;
    color: ${colors.adaGray};

    & + .error-message {
      margin-top: 0;
    }
  }
`;

const InputText = ({
  id,
  type = 'text',
  name = ' ',
  label = '',
  placeholder = '',
  helperMessage = '',
  value = '',
  mask = '',
  maskChar = '',
  className,
  onChange,
  onFocus,
  onClick,
  onBlur,
  error,
  checked,
  pattern,
  formNoValidate,
  autoComplete = 'on',
  ariaLabel = '',
  disabled,
  testId,
}) => {
  const { variant } = useAppVariant();
  return (
    <InputTextStyled
      className={`${className}${error && error.message ? ' input-error' : ''}${
        helperMessage ? ' helper-message' : ''
      }${variant === AppVariants.AccoladeCare ? ' acc-input' : ''}`}
      theme={themes[variant]}
    >
      {label && <label className="label" htmlFor={name}>{label}</label>}
      <div className="input-container">
        <InputMask
          className="input"
          id={id}
          type={type}
          name={name}
          autoComplete={autoComplete}
          placeholder={placeholder}
          value={value}
          mask={mask}
          maskChar={maskChar}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          checked={checked}
          pattern={pattern}
          formNoValidate={formNoValidate}
          aria-label={ariaLabel || label}
          data-private="lipsum" // TODO we need to remove this, as it shows in html if not override
          disabled={disabled}
          data-testid={testId}
        />
        {helperMessage && <div className="helper-message">{helperMessage}</div>}
        <ErrorMessage message={(error && error.message) || ''} />
      </div>
    </InputTextStyled>
  );
};

InputText.defaultProps = {
  type: 'text',
  name: ' ',
  label: '',
  placeholder: '',
  helperMessage: '',
  value: '',
  checked: false,
};

export default InputText;
