/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { StyledSelect } from 'src/js/nextgen/forms/Inputs/Select/Select.styled';
import { components } from 'react-select';

export const Select = props => {
  // 'off' is the default set in react-select. However, at the moment 'off' is ignored by chrome
  const autoComplete = props.autoComplete || 'off';
  const Input = (inputProps) => (
    <components.Input {...inputProps} autoComplete={autoComplete} readOnly={props.readOnly} />
  );
  const SingleValue = ({
    data,
    selectProps,
    ...singleValueProps
  }) => (
    <components.SingleValue {...singleValueProps}>
      {(props.prefix ?? '') + selectProps.getOptionLabel(data)}
    </components.SingleValue>
  );

  return (
    <StyledSelect
      classNamePrefix="react-select"
      {...props}
      components={{
        ...props.components,
        Input,
        SingleValue,
      }}
    />
  );
};
