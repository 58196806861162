import React from 'react';
import styled from '@emotion/styled';

const InnterStyled = styled.h1`

  margin-bottom: 20px;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  text-align: center;

  & + .nxg-form-subtitle {
    margin-top: -14px;
  }
`;

const FormTitle = (props) => {
  const { className, children } = props;
  return (
    <InnterStyled
      {...props}
      className={`${className || ''} nxg-form-title`.trim()}
    >
      { children }
    </InnterStyled>
  );
};

export default FormTitle;
