import React from 'react';
import styled from '@emotion/styled';

const InnterStyled = styled.p`

  text-align: center;
`;

const FormSubtitle = props => (
  <InnterStyled
    {...props}
    className={`${props.className || ''} nxg-form-subtitle`.trim()}
  >
    { props.children }
  </InnterStyled>
);

export default FormSubtitle;
