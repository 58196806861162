import React from 'react';
import { RadioStyled } from './RadioPill.styled';

const RadioPill = (props) => {
  const {
    id, label, selected,
  } = props;
  return (
    <RadioStyled className={`radio-pill${selected ? ' checked' : ''}`}>
      <input
        type="radio"
        {...props}
      />
      <label htmlFor={id}>{ label }</label>
    </RadioStyled>
  );
};

export default RadioPill;
