import React from 'react';
import styled from '@emotion/styled';
import FormContext from '../FormContext';

const InputStyled = styled.textarea`
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 4px;
  background-color: ${props => props.theme.colors.background};
  font-size: 16px;
  line-height: 20px;
  padding: 10px;
  margin-bottom: 8px;
  width: 100%;
  display: block;
`;

const TextAreaInput = (props) => {
  const {
    name, formik, disabled, readOnly, placeholder, className = '', rows, ariaLabel,
  } = props;
  const context = React.useContext(FormContext);
  // eslint-disable-next-line react/destructuring-assignment
  const frmk = formik || context.formik;

  const hasError = frmk?.touched[name] && frmk.errors[name];

  return (
    <InputStyled
      className={`input ${className} ${hasError ? 'is-danger' : ''}`.trim()}
      name={name}
      onChange={frmk.handleChange}
      onBlur={frmk.handleBlur}
      disabled={disabled || frmk.isSubmitting}
      placeholder={placeholder || ''}
      readOnly={readOnly}
      value={frmk.values[name] || ''}
      rows={rows}
      data-private="lipsum"
      aria-label={ariaLabel}
    />
  );
};

export default TextAreaInput;
