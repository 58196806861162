/* eslint-disable no-nested-ternary */
import React from 'react';

import {
  FieldContainer,
  Label,
  Error,
  Hint,
} from './LightField.styled';

export const LightField = ({
  label, help = '', name, children, error,
}) => (
  <FieldContainer>
    {label && <Label htmlFor={name}>{label}</Label>}
    {React.cloneElement(children, { name, error })}
    {help && <Hint>{help}</Hint>}
    {error && <Error data-testid={`input-error-${name}`}>{error}</Error>}
  </FieldContainer>
);
