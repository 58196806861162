// we need to add styles here as PlushCare design system forms
import styled from '@emotion/styled';
import Select from 'react-select';

import { baseInputStyles } from 'src/js/nextgen/forms/Inputs/BaseInput.styled';
import { colors } from 'src/js/nextgen/plushcare-components';

export const StyledSelect = styled(Select)`
  .react-select__control {
    ${props => baseInputStyles(props)}
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px ${props => props.theme.colors.primary};

    &:hover {
      border-color: ${props => props.theme.colors.primary};
    }

    &:active {
      border-color: ${props => props.theme.colors.foreground};
    }
  }

  .react-select__control--is-disabled {
    border-color: #ced4da;
    background-color: #e9ecef;
  }

  .react-select__single-value--is-disabled {
    color: ${colors.charcoal};
  }
`;
