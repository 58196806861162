import styled from '@emotion/styled';

import { colors } from '../../../plushcare-components/helpers/plushcare-style-defaults';

export const StyledFullScreenRow = styled.div`
  ${({ active }) => active
    && `
      @media(max-width:767px) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1051;
        width: 100%;
        height: 100vh;
        padding: 60px 30px 30px;
        margin: 0;
        background: ${colors.white};
    }`}
`;

export const StyledFullScreenContent = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  ${({ active }) => !active && `
      display: none; 
    `}
`;

export const StyledLink = styled.a`
  color: ${colors.adaRoyalBlue};
  cursor: pointer;
`;
