import styled from '@emotion/styled';
import AsyncSelect from 'react-select/async';

import { colors } from '../../../plushcare-components/helpers/plushcare-style-defaults';

import { baseInputStyles } from '../BaseInput.styled';

export const StyledAsyncSelect = styled(AsyncSelect)`

  .react-async-select__control {
    ${props => baseInputStyles(props)}
    padding: 0 6px 0 0;
    box-shadow: 0 0 0 0;
  }

  .react-async-select__placeholder {
    color: ${colors.adaGray};
  }

  .react-async-select__option--is-selected {
    background: ${colors.adaRoyalBlue};
  }

  .react-async-select__input, .react-async-select__single-value {
    color: ${props => props.theme.colors.foreground};
  }

  .react-async-select__control--is-focused {
    border-color: ${props => props.theme.colors.foreground};
    outline: none;
  }
  .react-async-select__menu {
    z-index: 9;
    margin: 0;
  }
`;
