import { zxcvbn } from '@zxcvbn-ts/core';
import { diffInYearsAgainstToday, isDateInPast } from '../../utils/datetime';

export const doesValueExist = value => (
  value && value.trim().length > 0
);

export const isValidDate = (date) => {
  if (!date) return false;
  let newDate = date;
  const [month, day] = newDate.split('/');

  let valid = true;

  if (
    month < 1
    || month > 12
    || day < 1
    || day > 31
  ) { return false; }

  if (newDate.length === 8 && newDate.match(/^\d+$/)) {
    const tempMonth = newDate.substr(0, 2);
    const tempDay = newDate.substr(2, 2);
    const tempYear = newDate.substr(4, 4);
    newDate = `${tempMonth}/${tempDay}/${tempYear}`;
  }

  if (newDate.length !== 10) {
    valid = false;
  }

  return valid;
};

export const isAdult = (dob) => {
  if (!dob) return false;

  let valid = true;

  const age = diffInYearsAgainstToday(dob);

  if ((age < 18 && age > 0) || Number.isNaN(age)) {
    valid = false;
  }

  return valid;
};

export const isValidAge = (dob) => {
  if (!dob) return false;

  let isValid = true;

  const age = diffInYearsAgainstToday(dob);

  if (age > 150 || Number.isNaN(age)) {
    isValid = false;
  }

  return isValid;
}

export const isGender = gender => {
  return ['F', 'M', 'U'].includes(gender);
};

export const isPhoneNumber = (value) => {
  if (!value) return false;
  const phoneNumber = value.replace(/-/g, '');
  return phoneNumber.length === 10;
};

export const isEmail = email => (
  !!email.match(
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
);

export const isValidPasswordLength = password => (
  /^.{8,}$/i.test(password)
);

export const doesPasswordHaveLetters = password => (
  /^(?=.*[a-z])(?=.*[A-Z])/i.test(password)
);

export const doesPasswordHaveNumbers = password => (
  /^(?=.*\d)/.test(password)
);

export const doesPasswordHaveSpecialCharacters = password => (
  /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)
);

export const isPasswordStrong = (password, targetComplexity = 1) => {
  return zxcvbn(password).score >= targetComplexity;
};

// Form specific
export const validateFirstName = (value, setFirstNameError) => {
  const isFirstNameValid = doesValueExist(value);
  if (isFirstNameValid) {
    setFirstNameError(null);
  } else {
    setFirstNameError({ message: 'Please enter a valid name.' });
  }
  return isFirstNameValid;
};

export const validateLastName = (value, setLastNameError) => {
  const isLastNameValid = doesValueExist(value);
  if (isLastNameValid) {
    setLastNameError(null);
  } else {
    setLastNameError({ message: 'Please enter a valid name.' });
  }
  return isLastNameValid;
};

export const validatePhoneNumber = (value, setPhoneError) => {
  const valid = isPhoneNumber(value);
  if (!valid) {
    setPhoneError({ message: 'Please enter a valid US phone number.' });
  } else {
    setPhoneError(null);
  }
  return valid;
};

export const validateGender = (value, setGenderError) => {
  const valid = isGender(value);
  if (!valid) {
    setGenderError({ message: 'Please select a gender.' });
  } else {
    setGenderError(null);
  }
  return valid;
};

export const validateDOB = (value, setDOBError, childOk = false, therapyValidation = false) => {
  const validDate = isValidDate(value);
  const validAdult = isAdult(value);
  const dobInPast = isDateInPast(value);
  const validAge = isValidAge(value);

  if (!validDate) {
    setDOBError({ message: 'Please enter a valid date of birth.' });
  } else if (!validAge) {
    setDOBError({ message: 'Please enter a valid birth year' });
  } else if (!childOk && !validAdult) {
    setDOBError({ message: `You must be 18 or older to book an appointment. ${therapyValidation ? 'Confidential therapy appointments for some members ages 12-17 may be scheduled by calling 1-800-221-5140.' : ''}` });
  } else if (!dobInPast) {
    setDOBError({ message: 'Date of birth must not be in the future.' });
  } else {
    setDOBError(null);
  }
  return validDate && validAdult;
};

export const EMAIL_IN_USE = 'Email in use';
export const EMAIL_INVALID = 'Invalid Email';

export const validateEmailString = (value, setEmailError, skipErrorClearing = false) => {
  const isValidEmail = isEmail(value);
  if (!isValidEmail) {
    setEmailError({ message: EMAIL_INVALID });
  } else if (!skipErrorClearing) {
    setEmailError(null);
  }

  return isValidEmail;
};

export const validatePassword = (password, setPasswordError, targetComplexity = 1) => {
  const validation = {
    passwordLengthValid: isValidPasswordLength(password),
    passwordHasLetters: doesPasswordHaveLetters(password),
    passwordHasNumbers: doesPasswordHaveNumbers(password),
    passwordHasSpecialCharacters: doesPasswordHaveSpecialCharacters(password),
    passwordIsStrong: isPasswordStrong(password, targetComplexity),
  };
  if (Object.values(validation).includes(false)) {
    setPasswordError({ message: 'Please enter a valid password.', ...validation });
  } else {
    setPasswordError(null);
  }
  return validation;
};

export const validateRelationship = (value, setRelationshipError) => {
  const isRelationshipValid = doesValueExist(value);

  if (isRelationshipValid) {
    setRelationshipError(null);
  } else {
    setRelationshipError({ message: 'Please select a valid relationship' });
  }
};

export const validateWeight = (value, setWeightError) => {
  const parsedValue = parseInt(value, 10);
  if (parsedValue > 0 && parsedValue < 500) {
    setWeightError(null);
  } else {
    setWeightError({ message: 'Please enter a valid weight' });
  }
};

export const validateHeight = (value, setHeightError) => {
  const { feet, inches } = value;
  let parsedFeet;
  let parsedInches;

  try {
    parsedFeet = parseInt(feet, 10);
    parsedInches = parseInt(inches, 10);
  } catch (error) {
    setHeightError({ message: 'Please enter a valid height' });
  }

  if (parsedFeet && parsedInches) {
    setHeightError(null);
  } else {
    setHeightError({ message: 'Please enter a valid height' });
  }
};

export const validateTosOptin = (value, setTosOptinError) => {
  if (value) {
    setTosOptinError(null);
  } else {
    setTosOptinError({ message: 'Please agree to our Terms of Use, Privacy Policy, and Telehealth Consent Policy.' });
  }
};

export const validateRace = (value, setRaceError) => {
  const valid = (value);
  if (!valid) {
    setRaceError({ message: 'Please select a race.' });
  } else {
    setRaceError(null);
  }
  return valid;
};

export const validateEthnicity = (value, setEthnicityError) => {
  const valid = (value);
  if (!valid) {
    setEthnicityError({ message: 'Please select an ethnicity.' });
  } else {
    setEthnicityError(null);
  }
  return valid;
};

export const validateUSZipCode = (value, setError) => {
  const valid = /^[0-9]{5}(-[0-9]{4})?$/.exec(value);
  if (!valid) {
    setError({ message: 'Please enter a valid zip code.' });
  } else {
    setError(null);
  }
  return valid && valid[0];
};
