import styled from '@emotion/styled';
import { colors } from '../../plushcare-components';

const RadioStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  input[type="radio"] {
    -webkit-appearance: none;
    position: relative;
    width: 22px;
    height: 22px;
    padding: 0;
    border: 4px solid ${colors.white};
    border-radius: 12px;
    margin-bottom: 0;
    background: ${colors.white};

    &::before {
      content: " ";
      position: absolute;
      top: -5px;
      left: -5px;
      display: block;
      width: 24px;
      height: 24px;
      border: 2px solid ${props => props.theme.colors.foreground};
      border-radius: 24px;
    }

    &:checked {
      background: ${props => props.theme.colors.foreground};
    }

    &:focus {
      outline: none;
    }
  }

  label {
    margin-bottom: 0;
  }

  .is-danger[type="radio"] {
    &::before {
      border: 1px solid ${colors.red};
    }
  }
`;

export default RadioStyled;
