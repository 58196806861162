import React from 'react';
import styled from '@emotion/styled';
import FormContext from '../FormContext';
import { colors } from '../../plushcare-components';

const SelectStyled = styled.select`
  box-sizing: border-box;
  border: 1px solid ${colors.plushcareGrey};
  border-radius: 4px;
  background-color: ${colors.white};
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  margin-bottom: 8px;
  width: 100%;
  display: block;
`;

const SelectInput = ({
  name, formik, className = '', children, disabled, ariaLabel, onChange,
}) => {
  const context = React.useContext(FormContext);
  // eslint-disable-next-line react/destructuring-assignment
  const frmk = formik || context.formik;

  const onSelectChange = (e) => {
    frmk.handleChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  const hasError = frmk.touched[name] && frmk.errors[name];
  return (
    <SelectStyled
      name={name}
      onChange={e => onSelectChange(e)}
      onBlur={frmk.handleBlur}
      disabled={disabled || frmk.isSubmitting}
      className={`input ${className} ${hasError ? 'is-danger' : ''}`.trim()}
      value={frmk.values[name] || ''}
      aria-label={ariaLabel}
    >
      {children}
    </SelectStyled>
  );
};

export default SelectInput;
