import { TextInput } from 'src/js/nextgen/forms/Inputs';
import Field from 'src/js/nextgen/forms/Fields/Field';
import FormContext from 'src/js/nextgen/forms/FormContext';
import React from 'react';

type TextInputFieldProps = {
  label?: string,
  name?: string,
  formik?: any,
  disabled?: boolean,
  readOnly?: boolean,
  placeholder: string,
  help?: string | null,
  css?: string | null,
  ariaLabel?: string | null,
  mask?: string | null,
  onClick?: () => void,
  testId?: string | null,
  maxLength?: number | null,
};

const TextInputField = ({
  label, name, formik = null, disabled = false, maxLength = null,
  readOnly = false, placeholder, help = null, css = null,
  ariaLabel = null, mask = null, onClick = () => { }, testId = null,
}: TextInputFieldProps) => {
  const context = React.useContext(FormContext);
  // eslint-disable-next-line
  const frmk = formik || context.formik;

  return (
    <Field label={label} formik={frmk} name={name} help={help} disableErrors={undefined}>
      <TextInput
        formik={frmk}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        css={css}
        ariaLabel={ariaLabel || label}
        mask={mask}
        onClick={onClick}
        testId={testId}
        maxLength={maxLength}
      />
    </Field>
  );
};

export default TextInputField;
