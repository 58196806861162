import React from 'react';
import styled from '@emotion/styled';
import { colors } from 'src/js/nextgen/plushcare-components';
import FormContext from '../../FormContext';
import ErrorMessage from '../../../plushcare-components/ErrorMessage';

const NumberedContainer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 300;
  }
`;

const RadioStyled = styled.div`

  display: inline-block;
  margin-right: 10px;

  @media (max-width: 600px) {
    margin-right: 5px;
  }

  input[type=radio] {
    -webkit-appearance: none;
    appearance: none;
    display: none;

    + label {
      color: ${props => props.theme.colors.foreground};
      font-size: 22px;

      span {
        display: inline-block;
        width: 50px;
        height: 50px;
        cursor: pointer;
        border-radius: 50%;
        border: 1px solid ${props => props.theme.colors.background};
        text-align: center;
        padding: 12px;
        background-color: ${props => props.theme.colors.background};
        line-height: 30px;
      }
    }

    &:checked + label span {
      color: ${props => props.theme.colors.foreground};
      background: ${colors.sunshine};
      border: 1px solid ${colors.sunshine};
    }
  }

  .is-danger[type=radio] {
    + label {
      span {
        color: ${colors.red};
        border: 1px solid ${colors.red};
      }
    }
  }
`;

const NumberedRadioGroup = ({
  name, formik, disabled, className = '', values, errorMsg, helpText = '',
  extra_content = null,
}) => {
  const context = React.useContext(FormContext);
  // eslint-disable-next-line react/destructuring-assignment
  const frmk = formik || context.formik;

  const hasError = frmk.touched[name] && frmk.errors[name];

  return (
    <NumberedContainer>
      <div className="radio-buttons">
        {values.length
          && values.map((value, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <RadioStyled key={i} className={className}>
              <input
                type="radio"
                name={name}
                className={`input ${hasError ? 'is-danger' : ''}`.trim()}
                id={`${name}-${i}`}
                onChange={frmk.handleChange}
                onBlur={frmk.handleBlur}
                disabled={disabled || frmk.isSubmitting}
                value={value.value}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <label htmlFor={`${name}-${i}`}>
                <span>
                  {value.label}
                </span>
              </label>
            </RadioStyled>
          ))}
        {extra_content && extra_content}
      </div>
      {helpText
        && <p className="help-text">{helpText}</p>}
      {hasError
        && <ErrorMessage message={errorMsg} />}
    </NumberedContainer>
  );
};

export default NumberedRadioGroup;
