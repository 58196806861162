import { FormikContextType, FormikValues } from 'formik';
import React from 'react';

type FormContextType = {
  formik: FormikContextType<FormikValues> | null;
};

const FormContext = React.createContext<FormContextType>({
  formik: null,
});

export const useForm = () => {
  const context = React.useContext(FormContext);
  if (context === undefined) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
};

export default FormContext;
