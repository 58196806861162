import React from 'react';
import styled from '@emotion/styled';

const InnterStyled = styled.div`

  color: ${props => props.theme.colors.foreground};
`;

const FormContent = props => (
  <InnterStyled
    {...props}
    className={`${props.className || ''} nxg-form-content`.trim()}
  >
    { props.children }
  </InnterStyled>
);

export default FormContent;
