import styled from '@emotion/styled';
import { colors } from '../../../plushcare-components';

export const RadioStyled = styled.div`
  padding: 10px 20px;
  background: ${colors.rain};
  border-radius: 30px;

  input[type=radio] {
    -webkit-appearance: none;
    position: relative;
    width: 12px;
    height: 12px;
    border: 1px solid ${props => props.theme.colors.foreground};
    border-radius: 6px;
    background: ${colors.rain};
    vertical-align: middle;

    &:focus {
      outline: 0;
    }
  }

  label {
    margin: 0 0 0 6px;
    color: ${props => props.theme.colors.foreground};
    width: 90%;
  }

  &.checked {
    background: ${colors.cloud};
    label {
      font-weight: 500;
    }
    input[type=radio] {
      background: ${props => props.theme.colors.foreground};
    }
  }
`;
