import * as yup from 'yup';
import {isPasswordStrong} from "../../utils/form-validators";

export const DEFAULT_PASSWORD_CHECKS = [
  ['pwd-length', v => v.length >= 8, '8 characters or more'],
  ['pwd-1-letter', v => /\D/.test(v), 'Contains at least 1 letter'],
  ['pwd-1-number', v => /\d/.test(v), 'Contains at least 1 number'],
  ['pwd-special-char', v => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v), 'Contains at least 1 special character'],
  ['pwd-strong', v => isPasswordStrong(v), 'Password strength']
];

function passwordMethod(checks = DEFAULT_PASSWORD_CHECKS) {
  function test(value) {
    const errors = [];
    for (let i = 0; i < checks.length; i += 1) {
      const [key, testFunc] = checks[i];
      if (!value || !testFunc(value)) {
        errors.push(key);
      }
    }
    if (errors.length) {
      return this.createError({
        message: errors.join('|'),
      });
    }
    return true;
  }

  return this.test('is-passwod', null, test);
}

yup.addMethod(yup.string, 'password', passwordMethod);

export default function password(label) {
  let res = yup
    .string()
    .ensure()
    .trim()
    .password();
  if (label) {
    res = res.label(label);
  }
  return res;
}
