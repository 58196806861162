import React from 'react';
import styled from '@emotion/styled';
import RadioStyled from 'src/js/nextgen/components/UserInput/RadioButton.styled';
import FormContext from '../../FormContext';
import ErrorMessage from '../../../plushcare-components/ErrorMessage';

const RadioLabel = styled.label`
  font-size: 18px;
  font-weight: 300;
  margin-left: 15px;
  position: relative;
  // top: -5px;
`;

const RadioButtons = ({
  name,
  formik,
  disabled,
  className = '',
  id = '',
  values,
  errorMsg,
}) => {
  const context = React.useContext(FormContext);
  // eslint-disable-next-line react/destructuring-assignment
  const frmk = formik || context.formik;

  const hasError = frmk.touched[name] && frmk.errors[name];

  return (
    <>
      {values.length
        && values.map((value, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <RadioStyled key={i}>
            <input
              type="radio"
              name={name}
              className={`input ${className} ${
                hasError ? 'is-danger' : ''
              }`.trim()}
              id={`${name}-${i}`}
              onChange={frmk.handleChange}
              onBlur={frmk.handleBlur}
              disabled={disabled || frmk.isSubmitting}
              value={value.value}
            />
            <RadioLabel htmlFor={id}>{value.label}</RadioLabel>
          </RadioStyled>
        ))}
      {hasError && <ErrorMessage message={errorMsg} />}
    </>
  );
};

export default RadioButtons;
