import React from 'react';
import FormContext from '../FormContext';
import { Button } from '../../plushcare-components';

const SubmitButton = (props) => {
  const {
    formik, children, fontSize = '20px', ...rest
  } = props;
  const context = React.useContext(FormContext);
  // eslint-disable-next-line react/destructuring-assignment
  const frmk = formik || context.formik;

  return (
    <Button
      type="submit"
      isDisabled={!(frmk.isValid && frmk.dirty)}
      isLoading={frmk.isSubmitting || props.isLoading}
      fontSize={fontSize}
      {...rest}
    >
      { children }
    </Button>
  );
};

export default SubmitButton;
