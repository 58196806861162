/* eslint-disable no-nested-ternary */
import React from 'react';

import FormContext from '../../FormContext';
import { LabelStyled, HelpText, FieldContainer } from './Field.styled';

const Field = ({
  label, help, formik, name, children, disableErrors,
}) => {
  const context = React.useContext(FormContext);
  // eslint-disable-next-line
  const frmk = formik || context.formik;

  const touched = frmk.touched[name];
  const error = frmk.errors[name];
  const hasError = touched && error;
  return (
    <FieldContainer className={`${hasError ? 'input-error' : ''}`.trim()}>
      {label && <LabelStyled className="label">{label}</LabelStyled>}
      <div className="control">
        {children}
        {!disableErrors && hasError
          ? <HelpText className="has-error">{error}</HelpText>
          : help
            ? <HelpText>{help}</HelpText>
            : null}
      </div>
    </FieldContainer>
  );
};

export default Field;
export { LightField } from './LightField';
