import React from 'react';
import InputMask from 'react-input-mask';
import { StyledInputMask } from './Date.styled';
import { getFormattedProfileDOBRxBooking, isDateValid } from '../../../../utils/datetime';

export const Date = ({ value, insuranceLookup, ...rest }) => {
  const val = isDateValid(value) ? getFormattedProfileDOBRxBooking(value) : value;

  return (
    <StyledInputMask>
      <InputMask
        placeholder="-- / -- / ----"
        mask="99/99/9999"
        value={insuranceLookup ? value : val}
        {...rest}
      />
    </StyledInputMask>
  );
};
